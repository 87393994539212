<template lang="pug">
  .app-tippy
    span.target(
      ref="target",
      @click="onClick"
    )
      slot(name="target")
    span.content(ref="content")
      slot(
        v-if="!lazyView || shown"
        name="content"
      )
</template>

<script>
  import { kebabCase } from "lodash-es"
  import Tippy, { sticky } from "tippy.js"

  import "tippy.js/dist/tippy.css"
  import "tippy.js/animations/shift-away.css"

  export default {
    props: {
      lazy: {
        type: Boolean,
        default: true
      },
      theme: {
        type: String,
        default: undefined
      },
      animation: {
        type: String,
        default: "shift-away"
      },
      appendTo: {
        type: [HTMLElement, String, Function],
        default: () => document.body
      },
      followReferenceBoundary: {
        type: Boolean,
        default: true
      },
      aria: undefined,
      arrow: {
        type: [Boolean, String],
        default: false
      },
      delay: {
        type: [Number, Array],
        default: 0
      },
      duration: {
        type: [Number, Array],
        default: 300
      },
      getReferenceClientRect: {
        type: Function,
        default: undefined
      },
      hideOnClick: {
        type: [Boolean, String],
        default: true // true | false | "toggle"
      },
      ignoreAttributes: {
        type: Boolean,
        default: true
      },
      interactive: {
        type: Boolean,
        default: true
      },
      interactiveBorder: {
        type: Number,
        default: 2
      },
      interactiveDebounce: {
        type: Number,
        default: 0
      },
      maxWidth: {
        type: [Number, String],
        default: "none"
      },
      moveTransition: {
        type: String,
        default: "none"
      },
      offset: {
        type: Array,
        default: () => [0, 0]
      },
      placement: {
        type: String,
        default: "bottom"
      },
      popperOptions: {
        type: Object,
        default: () => new Object()
      },
      showOnCreate: {
        type: Boolean,
        default: false
      },
      sticky: {
        type: [Boolean, String],
        default: false
      },
      touch: undefined,
      trigger: {
        type: String,
        default: "click" // manual | click | focusin | mouseenter | focus | combinations of them
      },
      triggerTarget: {
        type: Element,
        default: null
      },
      zIndex: {
        type: Number,
        default: 10000001 // z-index of AppOverlayLoader + 1
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        shown: false,
        lazyView: false,
        currentPlacement: undefined,
        currentIsOutOfBoundaries: undefined,
        defaultPopperOptions: {
          strategy: "fixed",
          modifiers: [
            {
              name: "flip",
              options: {
                fallbackPlacements: ["top"],
                altBoundary: true,
                padding: 2
              }
            },
            {
              name: "eventer",
              enabled: true,
              phase: "main",
              fn: ({ state }) => {
                if (this.currentPlacement !== state.placement) {
                  this.currentPlacement = state.placement
                  this.$emit("flip", this.currentPlacement)
                }

                const isOutOfBoundaries =
                  state.elements.popper.querySelector(".tippy-box").getAttribute("data-reference-hidden") !== null

                if (this.currentIsOutOfBoundaries !== isOutOfBoundaries) {
                  this.$emit("outOfBoundaries", this.currentIsOutOfBoundaries)
                }
              }
            },
            {
              name: "preventOverflow",
              options: {
                altBoundary: this.followReferenceBoundary,
                altAxis: true,
                tether: false,
                rootBoundary: document.getElementById("above-footer")
              }
            },
            {
              name: "sameWidth",
              enabled: true,
              phase: "beforeWrite",
              requires: ["computeStyles"],
              fn: ({ state }) => {
                state.styles.popper.width = `${state.rects.reference.width}px`
              },
              effect: ({ state }) => {
                state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`
              }
            }
          ]
        }
      }
    },

    mounted() {
      const normalizeEventName = eventName => kebabCase(eventName).replace("on-", "")
      const eventHandler = eventName => (...args) => this.$emit(normalizeEventName(eventName), args)

      // eslint-disable-next-line no-unused-vars
      const { lazy, followReferenceBoundary, disabled, ...config } = this.$props
      this.lazyView = lazy

      this.$tippy = Tippy(this.$refs.target, {
        ...config,
        content: this.$refs.content,
        plugins: [sticky],
        popperOptions: {
          ...this.defaultPopperOptions,
          ...this.popperOptions
        },
        role: "dropdown",
        onAfterUpdate: eventHandler("onAfterUpdate"),
        onBeforeUpdate: eventHandler("onBeforeUpdate"),
        onClickOutside: eventHandler("onClickOutside"),
        onCreate: eventHandler("onCreate"),
        onMount: eventHandler("onMount"),
        onShow: (...args) => {
          this.shown = true
          eventHandler("onShow")(args)
        },
        onShown: eventHandler("onShown"),
        onHidden: (...args) => {
          this.shown = false
          eventHandler("onHidden")(args)
        },
        onHide: eventHandler("onHide"),
        onTrigger: eventHandler("onTrigger"),
        onUntrigger: eventHandler("onUntrigger"),
        onDestroy: eventHandler("onDestroy")
      })
      if (disabled) {
        this.$tippy.disable()
      } else {
        this.$tippy.enable()
      }
    },

    methods: {
      async onClick() {
        if (this.trigger == "click") {
          this.lazyView = false
          await this.$nextTick()
        }
      }
    },
    watch: {
      disabled(newValue) {
        if (newValue) {
          this.$tippy.disable()
        } else {
          this.$tippy.enable()
        }
      },
      hideOnClick(hideOnClick) {
        this.$tippy.setProps({ hideOnClick })
      }
    }
  }
</script>

<style lang="sass" scoped>
  .app-tippy
    display: contents
</style>
