<template lang="pug">
  .app-select-body(:class="size")
    .app-select-actions(
      v-if="allowedSearch || allowedBatchSelect"
    )
      SearchAndTips(
        v-if="allowedSearch"
        v-model="searchText"
        :autofocus="autofocus"
        :search-placeholder="searchPlaceholder"
        :tips="tips"
        :with-search-icon="withSearchIcon"
        :opened="opened"
        @enter-pressed="handleSearchEnterPressed"
      )

      BatchActions.batch(
        v-if="allowedBatchSelect"
        @select-all="selectAll"
        @clear-all="clearAll"
      )

    .app-select-items(
      :class="{ multiple, expanded, 'draggable-selected': draggableSelected }"
      @scroll="handleElementScroll"
    )
      .no-data(v-if="opened && filteredItems.length === 0") {{ noDataPlaceholder }}
      template(v-if="multiple")
        DraggableSelected(
          v-if="draggableSelected && isEmpty(searchText)"
          :selected-items="selectedItems"
          :not-selected-items="notSelectedItems"
          :value-key="valueKey"
          :title-key="titleKey"
          :icon-key="iconKey"
          :icon-type="iconType"
          :tooltip-key="tooltipKey"
          :max-select-count="maxSelectCount"
          @drop="handleOrderedSelect"
          @select="selectItem(...$event)"
        )
        template(v-else)
          AppCheckbox.nowrap(
            v-for="(item, index) in filteredItems"
            :key="vueKey(item)"
            :value="isSelected(item)"
            :label="item[titleKey]"
            :disabled="!isSelected(item) && isItemDisabledByAllChecks(item)"
            @change="selectItem(item, index)"
          )
            template(
              v-if="item[iconKey]"
              v-slot:post-elem
            )
              .checkbox-postfix
                div(:id="vueKey(item) + 'tooltip'")
                AppTooltip(
                  :icon="item[iconKey]"
                  :type="item[iconType]"
                  :title="item[tooltipKey]"
                  :container="`#${vueKey(item)}tooltip`"
                )


      template(v-else)
        template(v-if="lazyScroll")
          AppLargeScroll(
            :items="opened ? filteredItems : []"
            :size-deps="[titleKey]"
            :buffer="2000"
            :key-field="valueKey"
            :initial-scroll-top="valueSet ? lastScrollTop : 0"
            v-slot="{ item, index }"
            @scroll="handleScroll"
          )
            DropdownItem(
              :key="vueKey(item)"
              :value="item[valueKey]"
              :title="item[titleKey]"
              :data-cy="item.dataCy"
              :selected="isSelected(item)"
              :disabled="!isSelected(item) && isItemDisabledByAllChecks(item)"
              :editable="editable"
              :deletable="deletable || (item.deletable && editable)"
              @select="selectItem(item, index)"
              @update="updateItem(item, index, $event)"
              @delete="deleteItem(item)"
            )
        template(v-else)
          DropdownItem(
            v-for="(item, index) in filteredItems"
            :key="vueKey(item)"
            :value="item[valueKey]"
            :title="item[titleKey]"
            :data-cy="item.dataCy"
            :selected="isSelected(item)"
            :disabled="!isSelected(item) && isItemDisabledByAllChecks(item)"
            :editable="editable"
            :deletable="deletable || (item.deletable && editable)"
            @select="selectItem(item, index)"
            @update="updateItem(item, index, $event)"
            @delete="deleteItem(item)"
          )
</template>

<script>
  // misc
  import { find, filter, trim, map, orderBy, isEmpty, uniqWith, isEqual, take } from "lodash-es"

  import AppLargeScroll from "@/components/elements/AppLargeScroll"

  export default {
    props: {
      opened: {
        type: Boolean,
        default: true
      },
      batchSelect: {
        type: Boolean,
        default: false
      },
      checkbox: {
        type: Boolean,
        default: false
      },
      editable: {
        type: Boolean,
        default: false
      },
      autofocus: {
        type: Boolean,
        default: true
      },
      deletable: {
        type: Boolean,
        default: false
      },
      value: {
        type: [Array, Object],
        default: () => new Array()
      },
      items: {
        type: Array,
        default: () => new Array()
      },
      selectedItems: {
        type: Array,
        default: () => new Array()
      },
      vueKey: {
        default() {
          return item => item[this.valueKey]
        }
      },
      valueKey: {
        type: String,
        default: "id"
      },
      titleKey: {
        type: String,
        default: "name"
      },
      iconKey: {
        type: String,
        default: "icon"
      },
      tooltipKey: {
        type: String,
        default: "tooltipText"
      },
      iconType: {
        type: String,
        default: "default"
      },
      placeholder: {
        type: String,
        default() {
          return this.$t("components.checkboxes_group.nothing_selected")
        }
      },
      noDataPlaceholder: {
        type: String,
        default() {
          return this.$t("no_data")
        }
      },
      searchable: {
        type: Boolean,
        default: false
      },
      searchPlaceholder: {
        type: String,
        default() {
          return this.$t("components.select.search")
        }
      },
      tips: String,
      multiple: {
        type: Boolean,
        default: false
      },
      creatable: {
        type: Boolean,
        default: false
      },
      closeOnSelect: {
        type: Boolean,
        default: false
      },
      allowEmpty: {
        type: Boolean,
        default: false
      },
      loading: {
        type: Boolean,
        default: false
      },
      expanded: {
        type: Boolean,
        default: false
      },
      isItemDisabled: {
        type: Function,
        default: () => false
      },
      // One of ["preorder", "keep", "asc", "desc"]
      orderDirection: {
        type: String
      },
      draggableSelected: {
        type: Boolean,
        default: false
      },
      size: {
        type: String,
        default: "medium",
        validator: value => ["small", "medium", "large"].includes(value)
      },
      lazyScroll: {
        type: Boolean,
        default: false
      },
      maxSelectCount: {
        type: Number
      },
      withSearchIcon: {
        type: Boolean,
        default: false
      }
    },

    components: {
      AppCheckbox: () => import("@/components/elements/AppCheckbox"),
      AppButton: () => import("@/components/elements/AppButton"),
      AppTooltip: () => import("@/components/elements/AppTooltip"),
      DropdownItem: () => import("./Item"),
      BatchActions: () => import("./BatchActions"),
      SearchAndTips: () => import("./SearchAndTips"),
      DraggableSelected: () => import("./DraggableSelected"),
      AppLargeScroll
    },

    data() {
      return {
        lastScrollTop: 0,
        searchText: "",
        drag: false
      }
    },

    computed: {
      allowedBatchSelect() {
        return this.batchSelect && this.multiple && this.items.length
      },

      allowedSearch() {
        return this.creatable || (this.searchable && this.items.length)
      },

      valueSet() {
        return !isEmpty(this.value)
      },

      enabledItems() {
        return filter(this.filteredItems, item => !this.isItemDisabled(item))
      },

      filteredItems() {
        let filterItems = this.items
        if (this.searchText !== "") {
          filterItems = filter(filterItems, item => this.compare(item[this.titleKey], this.searchText))
        }

        if (this.orderDirection === "preorder") {
          filterItems = orderBy(filterItems, "order", "ASC")
        } else if (this.orderDirection !== "keep") {
          filterItems = orderBy(filterItems, this.titleKey, this.orderDirection)
        }

        return filterItems
      },

      foundItem() {
        return find(this.filteredItems, item => this.compare(item[this.titleKey], this.searchText, true))
      },

      selectedItemIds() {
        return map(this.selectedItems, this.valueKey)
      },

      filteredItemIds() {
        return map(this.filteredItems, this.valueKey)
      },

      // need only for draggable-selected="true"
      notSelectedItems() {
        const items = this.filteredItems.filter(el => !this.selectedItemIds.includes(el[this.valueKey]))
        return orderBy(items, "order", "ASC")
      },

      isItemDisabledByMaxSelectCount() {
        if (this.maxSelectCount > 0) {
          return this.selectedItems.length === this.maxSelectCount
        }

        return false
      }
    },

    methods: {
      isEmpty,

      handleElementScroll(event) {
        this.handleScroll({ top: event.target.scrollTop })
      },

      handleScroll(scrollData) {
        this.lastScrollTop = scrollData.top
      },

      handleOrderedSelect(value) {
        this.$emit(
          "select",
          value.map((el, order) => ({ ...el, order }))
        )
      },

      compare(a, b, strict = false) {
        const item1 = trim(a).toLowerCase()
        const item2 = trim(b).toLowerCase()

        if (strict) {
          return item1 === item2
        } else {
          return item1.includes(item2)
        }
      },

      createItem() {
        this.$emit("create-item", trim(this.searchText))
      },

      resetSearch() {
        this.searchText = ""
      },

      handleSearchEnterPressed() {
        if (this.creatable && !this.foundItem) {
          this.createItem()
        } else if (this.filteredItems.length) {
          const targetItem = this.filteredItems[0]

          if (!this.isSelected(targetItem)) {
            this.selectItem(targetItem)
          }
        }

        this.resetSearch()
        this.closeOnSelect && this.$emit("hide-dropdown")
      },

      selectItem(item, index) {
        this.multiple ? this.multipleSelectItem(item, index) : this.singleSelectItem(item, index)
      },

      multipleSelectItem(item, originalIndex) {
        const selectedItems = [...this.selectedItems]
        const selectedIndex = this.selectedItemIds.indexOf(item[this.valueKey])

        if (selectedIndex === -1) {
          selectedItems.push(item)
        } else if (this.selectedItems.length > 1 || this.allowEmpty) {
          selectedItems.splice(selectedIndex, 1)
          this.$emit("remove", item, originalIndex)
        }

        if (this.draggableSelected) {
          this.handleOrderedSelect(selectedItems)
        } else {
          this.$emit("select", selectedItems)
        }
      },

      singleSelectItem(item, index) {
        const alreadySelectedItem = this.selectedItems[0]
        if (alreadySelectedItem && alreadySelectedItem[this.valueKey] === item[this.valueKey] && this.allowEmpty) {
          this.$emit("remove", item, index)
        } else {
          this.$emit("select", item)
        }

        if (this.closeOnSelect) {
          this.$emit("hide-dropdown")
          this.resetSearch()
        }
      },

      isSelected(item) {
        return this.selectedItemIds.includes(item[this.valueKey])
      },

      selectAll() {
        if (this.selectedItems.length === this.enabledItems.length) return

        if (this.draggableSelected) {
          this.handleOrderedSelect(this.enabledItems)
        } else {
          let newSelectedItems = uniqWith(
            [...this.selectedItems, ...this.enabledItems],
            (first, second) => this.vueKey(first) === this.vueKey(second)
          )
          newSelectedItems = this.maxSelectCount > 0 ? take(newSelectedItems, this.maxSelectCount) : newSelectedItems

          if (!isEqual(newSelectedItems, this.selectedItems)) {
            this.$emit("select", newSelectedItems)
          }
        }
      },

      clearAll() {
        if (this.searchText === "") {
          this.$emit("select", [])
        } else {
          const newSelectedItems = this.selectedItems.filter(el => !this.filteredItemIds.includes(el[this.valueKey]))

          if (!isEqual(newSelectedItems, this.selectedItems)) {
            this.$emit("select", newSelectedItems)
          }
        }
      },

      updateItem(item, index, updated) {
        this.$emit("update-item", { item, index, updated })
      },

      deleteItem(item) {
        this.$emit("delete-item", item[this.valueKey])
      },
      isItemDisabledByAllChecks(item) {
        const isDisabled = this.isItemDisabled(item)
        return isDisabled || this.isItemDisabledByMaxSelectCount
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/transitions.sass"
  @import "@/assets/styles/mixins/common.sass"

  .app-select
    &-body
      +flex-column-gapped
      padding: 6px 0
      list-style: none

      .app-select-actions
        +flex-column-gapped

        .batch-action
          height: 30px !important

      &.large
        ::v-deep
          *
            font-size: 1rem !important

      &.medium
        ::v-deep
          *
            font-size: 0.85rem !important
            white-space: nowrap
          .app-select-item-actions
            svg
              width: 10px !important
              height: 10px !important

      &.small
        ::v-deep
          *
            font-size: 0.7rem !important
          .app-select-item-actions
            svg
              width: 10px !important
              height: 10px !important


    &-items
      +flex-column-gapped
      gap: 0.25rem
      max-height: MIN(50vh, 250px)
      overflow-y: auto
      padding: 0 6px

      &.expanded
        max-height: MIN(55vh, 475px)

      &.multiple
        +flex-column-gapped

        ::v-deep
          .app-checkbox
            padding: 2px 6px

      &.draggable-selected
        padding: 0 2px 2px 2px

      .no-data
        color: $default-gray
        width: 100%
        display: flex
        justify-content: center
        align-items: center
        margin-top: 6px

      .nowrap
        .checkbox-postfix
          opacity: 1
          ::v-deep
            .tooltip-inner
              max-width: initial
</style>
