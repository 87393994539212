<template lang="pug">
  .removable-labels
    template(v-for="item in selectedItems")
      .removable-label(:class="{ inverted }")
        span {{ item[titleKey] }}
        AppIconButton.action-button(
          icon="plus-circle"
          @click.stop="$emit('remove', item)"
        )
</template>

<script>
  export default {
    components: {
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other")
    },

    props: {
      inverted: {
        type: Boolean,
        default: true
      },
      selectedItems: {
        type: Array,
        default: () => new Array()
      },
      titleKey: {
        type: String,
        default: "name"
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"

  .removable-labels
    width: 100%
    display: flex
    flex-wrap: wrap
    align-items: center
    justify-content: left

    .removable-label
      background: $default-gray-light
      border: 1px solid $border-element-color
      border-radius: 5px
      margin: 2px 3px 2px 0
      padding: 1px 4px 3px

      &.inverted
        background: $default-white

    .action-button
      +icon-button($default-purple)
      transform: rotate(45deg)
      width: 20px
      height: 20px
      margin-bottom: 2px
      margin-left: 5px

      &:hover
        box-shadow: none

      ::v-deep
        svg
          width: 16px
          height: 16px
</style>
