<template lang="pug">
  DynamicScroller(
    :items="items"
    :min-item-size="minItemSize"
    :buffer="buffer"
    :key-field="keyField"
    class="app-large-scroll"
    ref="scroller"
  )
    template(
      v-slot="{ item, index, active }"
    )
      DynamicScrollerItem(
        :item="item"
        :active="active"
        :size-dependencies="sizeDependencies(item)"
        :data-index="index"
        :emit-resize="false"
        :watch-data="false"
      )
        slot(
          :item="item"
          :index="index"
        )
</template>

<script>
  /*
   * Component to lazy render of large lists
   * It renders only visible (± buffer in px) items
   *
   * info: https://github.com/Akryum/vue-virtual-scroller#dynamicscroller
   */
  import "vue-virtual-scroller/dist/vue-virtual-scroller.css"
  import { DynamicScroller, DynamicScrollerItem } from "vue-virtual-scroller"

  import { debounce, isEmpty } from "lodash-es"

  export default {
    components: {
      DynamicScroller,
      DynamicScrollerItem
    },

    props: {
      items: {
        type: Array,
        required: true
      },
      minItemSize: {
        type: Number,
        default: 25 // min height of one element
      },
      buffer: {
        type: Number,
        default: 300 // amount of pixels above and below visible area
      },
      sizeDeps: {
        type: Array,
        default: () => new Array() // array of item fields to detect size dependencies
      },
      keyField: {
        type: String,
        default: "id"
      },
      initialScrollTop: {
        type: [String, Number],
        default: 0
      }
    },

    mounted() {
      this.scrollNode.onscroll = this.debouncedEmitScroll
      this.scrollToView()
    },

    watch: {
      items(value, prevValue) {
        if (isEmpty(prevValue) && !isEmpty(value)) {
          this.scrollToView()
        }
      }
    },

    beforeDestroy() {
      this.scrollNode.onscroll = null
    },

    computed: {
      isActive() {
        return !isEmpty(this.items)
      },

      scrollNode() {
        return this.$refs.scroller.$el
      }
    },

    methods: {
      scrollToView() {
        if (this.initialScrollTop > 0) {
          setTimeout(() => {
            this.scrollNode.scrollTo({ top: this.initialScrollTop, behavior: "smooth" })
          }, 250)
        }
      },

      debouncedEmitScroll: debounce(function(e) {
        this.isActive && this.$emit("scroll", { top: e.target.scrollTop })
      }, 100),

      sizeDependencies(item) {
        return this.sizeDeps.map(field => item[field])
      }
    }
  }
</script>
